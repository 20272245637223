import React from "react";
import { motion } from "framer-motion";
import './styles/boxescore.scss';

const BoxesCore = ({ className, ...rest }) => {
    const rows = new Array(40).fill(1);
    const cols = new Array(40).fill(1);
    const colors = [
      '--sky-300',
      '--pink-300',
      '--green-300',
      '--yellow-300',
      '--red-300',
      '--purple-300',
      '--blue-300',
      '--indigo-300',
      '--violet-300',
    ];
  
    const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];
  
    return (
        <div
            className={`background-boxes ${className}`}
            {...rest}
        >
        {rows.map((_, i) => (
          <motion.div key={`row-${i}`} className="row">
            {cols.map((_, j) => (
              <motion.div
                whileHover={{
                  backgroundColor: `var(${getRandomColor()})`,
                  transition: { duration: 0 },
                }}
                animate={{
                  transition: { duration: 2 },
                }}
                key={`col-${j}`}
                className="col"
              >
                {/* Remove the SVG rendering here */}
              </motion.div>
            ))}
          </motion.div>
        ))}
      </div>
    );
};

export const Boxes = React.memo(BoxesCore);
