import React from 'react';
import Form from './components/Form.jsx';
import BookCall from './components/BookCall.jsx';
import { Boxes } from './components/BoxesCore.jsx';


function Page() {
  return (
    <div className="page">
      <Boxes />
      <Form />
      <BookCall />
    </div>
  );
}

export default Page;
