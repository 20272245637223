import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";

export function PlaceholdersAndVanishInput({
    placeholders,
    onChange,
    onSubmit,
    value,
    type,
  }, ref) {
    const [currentPlaceholder, setCurrentPlaceholder] = useState(0);
  
    useEffect(() => {
      let interval;
      const startAnimation = () => {
        interval = setInterval(() => {
          setCurrentPlaceholder((prev) => (prev + 1) % placeholders.length);
        }, 1500);
      };
      startAnimation();
      return () => clearInterval(interval);
    }, [placeholders.length]);
  
    const canvasRef = useRef(null);
    const newDataRef = useRef([]);
    const inputRef = useRef(null);
    const [animating, setAnimating] = useState(false);
  
    const draw = useCallback(() => {
      if (!inputRef.current) return;
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;
  
      canvas.width = 800;
      canvas.height = 800;
      ctx.clearRect(0, 0, 800, 800);
      const computedStyles = getComputedStyle(inputRef.current);
  
      const fontSize = parseFloat(computedStyles.getPropertyValue("font-size"));
      ctx.font = `${fontSize * 2}px ${computedStyles.fontFamily}`;
      ctx.fillStyle = "#FFF";
      ctx.fillText(value, 16, 40);
  
      const imageData = ctx.getImageData(0, 0, 800, 800);
      const pixelData = imageData.data;
      const newData = [];
  
      for (let t = 0; t < 800; t++) {
        let i = 4 * t * 800;
        for (let n = 0; n < 800; n++) {
          let e = i + 4 * n;
          if (
            pixelData[e] !== 0 &&
            pixelData[e + 1] !== 0 &&
            pixelData[e + 2] !== 0
          ) {
            newData.push({
              x: n,
              y: t,
              color: [
                pixelData[e],
                pixelData[e + 1],
                pixelData[e + 2],
                pixelData[e + 3],
              ],
            });
          }
        }
      }
  
      newDataRef.current = newData.map(({ x, y, color }) => ({
        x,
        y,
        r: 1,
        color: `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`,
      }));
    }, [value]);
  
    useEffect(() => {
      draw();
    }, [value, draw]);
  
    const animate = (start) => {
      const animateFrame = (pos = 0) => {
        requestAnimationFrame(() => {
          const newArr = [];
          for (let i = 0; i < newDataRef.current.length; i++) {
            const current = newDataRef.current[i];
            if (current.x < pos) {
              newArr.push(current);
            } else {
              if (current.r <= 0) {
                current.r = 0;
                continue;
              }
              current.x += Math.random() > 0.5 ? 1 : -1;
              current.y += Math.random() > 0.5 ? 1 : -1;
              current.r -= 0.05 * Math.random();
              newArr.push(current);
            }
          }
          newDataRef.current = newArr;
          const ctx = canvasRef.current?.getContext("2d");
          if (ctx) {
            ctx.clearRect(pos, 0, 800, 800);
            newDataRef.current.forEach((t) => {
              const { x: n, y: i, r: s, color: color } = t;
              if (n > pos) {
                ctx.beginPath();
                ctx.rect(n, i, s, s);
                ctx.fillStyle = color;
                ctx.strokeStyle = color;
                ctx.stroke();
              }
            });
          }
          if (newDataRef.current.length > 0) {
            animateFrame(pos - 8);
          } else {
            setAnimating(false);
          }
        });
      };
      animateFrame(start);
    };
  
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && !animating) {
        if(inputRef.current?.value){
          vanishAndSubmit();
        }
      }
    };
  
    const vanishAndSubmit = () => {
      setAnimating(true);
      draw();
  
      const inputValue = inputRef.current?.value || "";
      if (inputValue && inputRef.current) {
        const maxX = newDataRef.current.reduce(
          (prev, current) => (current.x > prev ? current.x : prev),
          0
        );
        animate(maxX);
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      vanishAndSubmit();
      onSubmit && onSubmit(e);
    };
  
    return (
      <form
        className={`placeholder-vanish-form ${value ? "input-filled" : ""}`}
        onSubmit={handleSubmit}
      >
        <canvas
          className={`placeholder-vanish-canvas ${animating ? "visible" : "hidden"}`}
          ref={canvasRef}
        />
        <div className="input-wrapper">
          <input
            autoFocus
            onChange={(e) => {
              if (!animating) {
                onChange(e);
              }
            }}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            value={value}
            type={type}
            className={`placeholder-vanish-input ${animating ? "text-transparent" : ""}`}
            placeholder={value ? '' : placeholders[currentPlaceholder]}
          />
          <button
            disabled={!value}
            type="submit"
            className={`placeholder-vanish-button ${value ? "input-active" : ""}`}
          >
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="icon"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <motion.path
                d="M5 12l14 0"
                initial={{
                  strokeDasharray: "50%",
                  strokeDashoffset: "50%",
                }}
                animate={{
                  strokeDashoffset: value ? 0 : "50%",
                }}
                transition={{
                  duration: 0.3,
                  ease: "linear",
                }}
              />
              <path d="M13 18l6 -6" />
              <path d="M13 6l6 6" />
            </motion.svg>
          </button>
        </div>
      </form>
    );
  }
  
