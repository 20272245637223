import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { PlaceholdersAndVanishInput } from './VanishInput.jsx';
import CalButton from './CalButton.jsx';
import './styles/form.scss';

const placeholder = [
  'Save up to 90% of your time with automation...',
  'Enhance customer satisfaction and loyalty...',
  'Boost your sales and marketing efficiency...',
  "Discover your perfect automation solution...",
];

const questions = [
    {
        question: "What is your business' name?",
        caption: "Your business name.",
    },
    {
        question:"What industry does your business belong to?",
        caption: "Describe your primary products or services briefly."
    },
    {
        question: "What are the main operational challenges your business faces?",
        caption: "Please list your challenges in detail.",
    },
    {
        question: "What software tools are essential for your daily operations?",
        caption: "List top 3-5 tools you use.",
    },
    {
        question: "Are there any repetitive tasks that you believe could be automated?",
        caption: "Think of all the processes that are repetitive.",
    },
    {
        question: "How do you manage data and information flow within your organization?",
        caption: "Describe your data and information management practices.",
    },
    {
        question: "What are your current marketing and sales processes?",
        caption: "Briefly outline your approach to marketing and sales.",
    },
    {
        question: "How do you measure the success and performance in your business?",
        caption: "To Understand Your KPIs and Metrics.",
    },
    {
        question: "Thank you for answering! Please Enter Your Email Below",
        caption: "You will receive a personalized report with free advice and improvement suggestions.",
    }
]

const Form = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const questionKey = currentQuestion === questions.length - 1 ? 'email' : questions[currentQuestion].question;
    const updatedAnswers = {...answers, [questionKey]: inputValue};
    setAnswers(updatedAnswers);
    setInputValue('');

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // Submit to webhook
      await fetch(process.env.REACT_APP_WEBHOOK, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedAnswers),
      });
      setSubmitted(true);
    }
  };

  const handleBack = () => {
      if (currentQuestion > 0) {
          setCurrentQuestion(currentQuestion-1);
          setInputValue(answers[questions[currentQuestion - 1].question] || '');
      }
  }

  return (
    <div className="form-container">
      {!submitted ? (
        <AnimatePresence mode='wait'>
          <motion.div
            key={currentQuestion}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
          >
            <motion.h2 className="question-text">
              {questions[currentQuestion].question}
            </motion.h2>
            <p className="caption-text">{questions[currentQuestion].caption}</p>
            <PlaceholdersAndVanishInput
              placeholders={placeholder}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
              value={inputValue}
              type={currentQuestion === questions.length - 1 ? 'email' : 'text'}
            />
            <div className="button-container">
              {currentQuestion > 0 && (
                <button onClick={handleBack} className="back-button">
                  Back
                </button>
              )}
              {currentQuestion === questions.length - 1 && (
                <button onClick={handleSubmit} className="submit-button">
                  Submit
                </button>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="submission-message"
        >
          <h2>Thank you!</h2>
          <p>We have received your answers. You will shortly receive a personalised report with free advice and implementation suggestions.</p>
          <div className="next-steps">
            <h3>Next Steps:</h3>
            <CalButton text="Let's Talk!"/>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Form;
