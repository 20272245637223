import { useEffect } from "react";
import { getCalApi } from "@calcom/embed-react";

export default function CalButton({text}){
    useEffect(()=>{
        (async function () {
          const cal = await getCalApi({});
          cal("ui", {"theme":"dark","styles":{"branding":{"brandColor":"#000000"}},"hideEventTypeDetails":false,"layout":"month_view"});
        })();
    }, [])
    return <button data-cal-namespace=""
    data-cal-link="saurav-kc-flow-fusion/automation-consultation"
    
    data-cal-config='{"layout":"month_view"}'
    >{text}</button>;
}