import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPhoneAlt } from 'react-icons/fa';
import CalButton from './CalButton';

import './styles/book.scss';



const BookCall = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [bobbing, setBobbing] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setBobbing(!bobbing);
    }, 5000); // Change bobbing every 5 seconds

    return () => clearInterval(interval);
  }, [bobbing]);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="top-right-container">
      <motion.div
        className="attention-grabber"
        initial={{ x: 0 }}
        animate={{ x: bobbing ? [0, -10, 10, -10, 10, 0] : 0 }}
        transition={{
          duration: 0.5,
          ease: "easeInOut",
        }}
        onClick={handleClick}
      >
        <FaPhoneAlt size={24} color="#FFF" />
        <AnimatePresence>
          {isVisible && (
            <motion.div
              className="book-call-button"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 50 }}
              transition={{ duration: 0.3 }}
            >
              <CalButton text="Book A Call"/>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default BookCall;
